<template>
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p
            class="large--title m-0"
            :class="mode ? 'text__day2' : 'text__night2'"
          >
            {{
              $t("message.new_m", {
                m: $t("message.bonuses")
              })
            }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0">
        <div
          class="timeline-items__right rounded-sm w-100 p-4"
          :class="mode ? 'table__myday' : 'table__mynight'"
        >
          <el-form ref="form" :model="form" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item prop="staff_id">
                  <span
                    :class="mode ? 'text__day2' : 'text__night2'"
                    class="input--label d-block mb-0 line-h-24"
                    >{{ $t("message.staff") }}</span
                  >
                  <select-staff
                    :id="form.staff_id"
                    v-model="form.staff_id"
                  ></select-staff>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="period">
                  <span
                    :class="mode ? 'text__day2' : 'text__night2'"
                    class="input--label d-block mb-0 line-h-24"
                    >{{ $t("message.period") }}</span
                  >
                  <el-select
                    v-model="form.periot"
                    :placeholder="$t('message.period')"
                    class="w-100"
                    popper-class="my-data-khan"
                  >
                    <el-option
                      :label="$t('message.for_today')"
                      value="today"
                    ></el-option>
                    <el-option
                      :label="$t('message.for_tomorrow')"
                      value="tomorrow"
                    ></el-option>
                    <el-option
                      :label="$t('message.this_week')"
                      value="this_week"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="amount">
                  <span
                    :class="mode ? 'text__day2' : 'text__night2'"
                    class="input--label d-block mb-0 line-h-24"
                    >{{ $t("message.amount") }}</span
                  >
                  <crm-input
                    :placeholder="$t('message.amount')"
                    :inputValue="form.amount"
                    v-model="form.amount"
                    :type="'number'"
                    size="medium"
                    :class="mode ? 'input__day' : 'input__night'"
                  ></crm-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="month">
                  <span
                    :class="mode ? 'text__day2' : 'text__night2'"
                    class="input--label d-block mb-0 line-h-24"
                    >{{ $t("message.monthly") }}</span
                  >
                  <el-date-picker
                    class="w-100"
                    v-model="form.month"
                    type="month"
                    :format="'MMMM yyyy'"
                    :value-format="'MM.yyyy'"
                    :placeholder="$t('message.monthly')"
                    :picker-options="pickerOptions"
                    popper-class="my-data-khan"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <span
                    :class="mode ? 'text__day2' : 'text__night2'"
                    class="input--label d-block mb-0 line-h-24"
                    >{{ $t("message.comment") }}</span
                  >
                  <el-input
                    type="textarea"
                    :rows="2"
                    :placeholder="$t('message.comment')"
                    :inputValue="form.comment"
                    v-model="form.comment"
                    class="text-area-el"
                    :class="mode ? 'input__day' : 'input__night'"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>
import selectStaff from "@/components/filters/inventory/select-staff";
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
export default {
  mixins: [drawer, form],
  components: { selectStaff },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      rules: "advanceSalary/rules",
      model: "advanceSalary/model",
      columns: "advanceSalary/columns",
      mode: "MODE"
    })
  },

  methods: {
    ...mapActions({
      save: "advanceSalary/addSalary"
    }),

    submit(close = true) {
      console.log(this.form);
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then(res => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (res.status == 201) {
                this.close();
              }
            })
            .catch(err => {
              this.loadingButton = false;
              this.$message({
                showClose: true,
                type: "error",
                duration: 8000,
                dangerouslyUseHTMLString: true,
                message: err.data.error.message
              });
            });
        }
      });
    }
  }
};
</script>
